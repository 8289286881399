/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import SEO from "./seo";
import "./layout.less";
import styles from "./layout.module.less";

import "../utils/i18n";
import { useTranslation } from "react-i18next";

import BannerEnDesktopImage from "../images/EPD-lamppost-sensor-data_inside-banner_en.jpg";
import BannerEnMobileImage from "../images/EPD-lamppost_mobile_banner-low.png";
import BannerTcDesktopImage from "../images/EPD-lamppost-sensor-data_inside-banner_tc.jpg";
import BannerTcMobileImage from "../images/EPD-lamppost_mobile_tri_Chi_banner_low.png";
import BannerScDesktopImage from "../images/EPD-lamppost-sensor-data_inside-banner_sc.jpg";
import BannerScMobileImage from "../images/EPD-lamppost_mobile_simp_Chi_banner_low.png";
import BackgroundImage from "../images/EPD-lamppost-desktop_backg_png8.png";
import W3CImage from "../images/w3c_wcag_aa.png";

const Layout = ({ lang, path, i18nSEO, useDefBackground, children }) => {
    const [fontSizeClass, setFontSizeClass] = useState(
        (typeof window !== `undefined` &&
            window.localStorage &&
            window.localStorage.getItem("fontSize")) ||
            "textMedium"
    );
    const { t, i18n } = useTranslation();

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang, i18n]);

    let textSizeClass;
    switch (fontSizeClass) {
        case "textSmall":
            textSizeClass = styles.textSmall;
            break;
        case "textMedium":
            textSizeClass = styles.textMedium;
            break;
        case "textLarge":
            textSizeClass = styles.textLarge;
            break;
        default:
            textSizeClass = styles.textMedium;
    }

    let BannerDesktopImage, BannerMobileImage;
    switch (lang) {
        case "en":
            BannerDesktopImage = BannerEnDesktopImage;
            BannerMobileImage = BannerEnMobileImage;
            break;
        case "tc":
            BannerDesktopImage = BannerTcDesktopImage;
            BannerMobileImage = BannerTcMobileImage;
            break;
        case "sc":
            BannerDesktopImage = BannerScDesktopImage;
            BannerMobileImage = BannerScMobileImage;
            break;
        default:
            BannerDesktopImage = BannerEnDesktopImage;
            BannerMobileImage = BannerEnMobileImage;
            break;
    }

    return (
        <>
            <SEO title={t(i18nSEO)} lang={lang} />
            <Header
                siteTitle={t(data.site.siteMetadata.title)}
                setFontSizeClass={setFontSizeClass}
                fontSizeClass={fontSizeClass}
                lang={lang}
                path={path}
            />
            <div className={styles.imgHeader}>
                <div
                    className="desktop"
                    style={{
                        backgroundImage: `url(${BannerDesktopImage})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100% 100%",
                        paddingBottom: "10.7%",
                    }}
                    aria-label={t("Portal for Air Quality Sensors")}
                    role="banner"
                ></div>
                <img
                    src={BannerMobileImage}
                    style={{ width: "100%" }}
                    className="mobile"
                    alt=""
                />
                <h1 className="hidden">
                    {t("Portal for Air Quality Sensors")}
                </h1>
            </div>
            {useDefBackground ? (
                <div
                    style={{
                        padding: "1rem",
                        backgroundImage: `url(${BackgroundImage})`,
                        backgroundRepeat: "repeat-y",
                    }}
                >
                    <main className={`${textSizeClass} ${lang} ${styles.main}`}>
                        {children}
                    </main>
                </div>
            ) : (
                <div className={`${textSizeClass} ${lang}`}>{children}</div>
            )}
            <footer className={styles.footer}>
                <div className={styles.footerList}>
                    <div className={styles.footerItem + " pb3 text-sm"}>
                        {t("Website Last Revision Date")}:{" "}
                        <span className="inline-block">2025-01-27</span>
                    </div>
                    <div
                        style={{
                            margin: "auto",
                        }}
                        className={styles.footerItem}
                    >
                        <a
                            href="https://www.w3.org/WAI/WCAG2AA-Conformance"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={t("WCAG2AA")}
                        >
                            <img
                                style={{
                                    margin: 0,
                                    height: 32,
                                    width: 88,
                                }}
                                src={W3CImage}
                                alt={t("WCAG2AA")}
                            />
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
};

Layout.propTypes = {
    lang: PropTypes.string.isRequired,
    path: PropTypes.string,
    i18nSEO: PropTypes.string.isRequired,
    useDefBackground: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
    path: "",
    useDefBackground: true,
};

export default Layout;
